






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// .head {
// 	background-color: #ffffff;
// 	padding: 0.24rem 0.25rem;
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
	.head_left {
		display: flex;
		align-items: center;
		.head_left_1 {
			margin-top: 0.2rem;
			img {
				width: 0.46rem;
				height: 0.46rem;
			}
		}
		.head_left_2 {
			margin: 0.1rem;
			img {
				width: 1.24rem;
				height: 0.15rem;
			}
		}
	}
	.head_right {
		display: flex;
		align-items: center;
		margin-top: 0.2rem;
		.xiaoxi {
			img {
				width: 0.4rem;
				height: 0.4rem;
			}
		}
		.yuyanqiehuan {
			margin-left: 0.59rem;
			img {
				width: 0.4rem;
				height: 0.4rem;
			}
		}
	}
// }
.head_bgcolor {
	background-color: #ffffff;
	padding: 0 0.2rem;
	margin-bottom: 0.2rem;
	padding-top: 0.1rem;
}
.layoutBox {
	padding: 0 0.3rem;
}
.menuBox {
	background-color: #ffffff;
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
	padding: 0.28rem 0.2rem 0.28rem 0.2rem;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0.2rem;
	// box-shadow: 0 2px 18px rgba(202, 202, 202, 1);
}
.swipeBanner {
	height: 2.72rem;
	margin-bottom: 0.02rem;
	border-radius: 0.16rem;
	overflow: hidden;
	.banner {
		width: 7.3rem;
		height: 2.72rem;
		display: block;
		border-radius: 0.16rem;
		overflow: hidden;
	}
	.custom-indicator {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0.1rem;
		display: flex;
		.custom-indicator-item{
			margin: 0 0.06rem;
		}
	}
}
.swipeNotice {
	height: 0.56rem;
	line-height: 0.6rem;
	padding: 0 0.15rem 0 0.65rem;
	background: #fff url(../../assets/imgs/new_color/xiaoxi.png) no-repeat left 0.15rem center;
	background-size: 0.28rem 0.28rem;
	border-radius: 0.1rem;
	// margin-bottom: 0.15rem;
	overflow: hidden;
	color: #14181f;
	font-size: 0.24rem;
	transform: scale(0.916);
	margin-left: -0.4rem;
	.xiaoxi_logo {
		padding-top: 0.18rem;
		margin-right: 0.15rem;
		width: 0.3rem;
		height: 0.3rem;
	}
	.icon_arrR {
		float: right;
		margin: 0.2rem 0.1rem 0 0.2rem;
	}
	.van-swipe-item {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
.rankBox {
	height: 4.2rem;
	margin: 0.2rem 0;
	padding: 0 0.2rem;
	// padding-bottom: 0;
	// margin-bottom: 0.25rem;
	background-image: url(../../assets/imgs/new_color/paihang_bg.png);
	background-size: 100% 4.2rem;
	background-repeat: no-repeat;
	position: relative;
	.caption {
		padding-top: 0.2rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		// position: relative;
		height: 0.6rem;
		margin: 0 0.21rem 0 0.05rem;
		margin-bottom: 0.8rem;
		// &::before {
		// 	content: '';
		// 	width: .04rem;
		// 	height: .24rem;
		// 	background: #90C4FF;
		// 	border-radius: 2px;
		// 	position: absolute;
		// 	left: 0;
		// 	top: 50%;
		// 	margin-top: -.12rem;
		// }
		.title {
			font-family: PingFangSC-Medium;
			font-size: 0.32rem;
			color: #333333;
			font-weight: 600;
			// margin-left: .14rem;
		}
		.more {
			font-size: 0.24rem;
		}
		.icon_arrR {
			width: 0.18rem;
			height: 0.18rem;
		}
	}
	.list {
		display: flex;
		// justify-content: space-between;
		align-items: flex-end;
	}
}

.indexTabList {
	margin-top: 10px;
	padding: 0 0.31rem 0 0.26rem;
	.tabTit {
		color: #999999;
		font-size: 0.32rem;
		font-family: 'Bahnschrift', 'PingFangSC-Regular', 'PingFang SC', '微软雅黑', 'Microsoft Yahei';
		line-height: 0.7rem;
		height: 0.7rem;
		border-bottom: 1px solid #ededed;
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.1rem;
		text-transform: capitalize;
		li {
			padding-bottom: 0.68rem;
		}
		.on {
			color: #373737;
			font-size: 0.4rem;
			font-weight: bold;
			border-bottom: 1px solid #373737;
		}
	}
}
.menuLine {
	// margin: 0.33rem 0.245rem 0;
	padding: 8px 0;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.menuName {
		font-size: 0.24rem;
		color: #373737;
		display: inline-block;
		margin-top: 0.03rem;
	}

	.menuItem {
		font-size: 0.28rem;
		// font-weight: 600;
		color: #8f9eab;
		line-height: 0.6rem;
		// text-align: center;
		// padding: 0.01rem 0 0.02rem;
		// display: inline-block;
		// width: 25%;
		// padding-left: 0.2rem;
		padding: 0 0.3rem;
		min-width: 20%;
		position: relative;
		text-transform: capitalize;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		&:first-child {
			padding-left: 0;
			&::before {
				display: none;
			}
		}
		&:last-child {
			padding-right: 0;
		}
	}

	.menuItem:last-child {
		margin-right: 0;
	}

	.menuItem.on {
		font-family: PingFangSC-Medium;
		// background: #fc9216;
		// color: #90c4ff;
		font-weight: 600;
		font-size: 0.28rem;
		color: #333333;
		// border-right: 1px solid #fc9216;
	}
	.dropdownBtn {
		color: #333333;
		font-size: 0.28rem;
		font-weight: bold;
	}
}

.menuLine1 {
	// margin: 0.33rem 0.245rem 0;
	padding: 8px 0;
	position: relative;
	display: flex;
	align-items: center;

	.menuName {
		font-size: 0.24rem;
		color: #373737;
		display: inline-block;
		margin-top: 0.03rem;
	}

	.menuItem {
		font-size: 0.28rem;
		color: #8f9eab;
		line-height: 0.6rem;
		padding: 0 0.3rem;
		min-width: 20%;
		position: relative;
		text-transform: capitalize;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		&:first-child {
			padding-left: 0;
			padding-right: 0.6rem;
			&::before {
				display: none;
			}
		}
		&:last-child {
			padding-right: 0;
		}
	}

	.menuItem:last-child {
		margin-right: 0;
	}

	.menuItem.on {
		font-family: PingFangSC-Medium;
		// background: #fc9216;
		// color: #90c4ff;
		font-weight: 600;
		font-size: 0.28rem;
		color: #333333;
		// border-right: 1px solid #fc9216;
	}
	.dropdownBtn {
		color: #333333;
		font-size: 0.28rem;
		font-weight: bold;
	}
}

.indexLoading {
	text-align: center;
	padding-top: 1rem;
}

.bottomTips {
	display: flex;
	justify-content: space-between;
	position: fixed;
	left: 0;
	bottom: calc(env(safe-area-inset-bottom) + 1.18rem);
	height: 0.28rem;
	width: 100%;
	background-color: #f2f2f2;
	padding: 0 0.1rem;
	.z_left {
		font-size: 0.24rem;
		color: #333333;
		transform: scale(0.83);
	}
	.z_right {
		font-size: 0.24rem;
		color: #333333;
		transform: scale(0.83);
	}
}
.list-item0 {
	margin-left: 0.2rem;
}
.list-item2 {
	margin-left: 0.2rem;
}
.container {
	font-family: PingFangSC-Regular;
	background-color: #f5f5f5;
}
.new_list {
	font-size: 12px;
	color: #ffffff;
	padding: 0.2rem 0.4rem 0.2rem 0.3rem;
	border-radius: 6px;
	background-image: linear-gradient(to right, #8ec4ff, #ddacff);
	background-color: #8ec4ff;
	// FILTER: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, StartColorStr=#8EC4FF, EndColorStr=#DDACFF); /*IE6-IE8*/
	// background-image: -ms-linear-gradient(right, #8EC4FF,  #DDACFF);        /* IE 10 */
	// background-image:-moz-linear-gradient(right, #8EC4FF,#DDACFF);/*火狐*/
	// background-image:-webkit-gradient(linear, 0% 100%, 0% 100%,from(#8EC4FF), to(#DDACFF));/*谷歌*/
	// background-image: -webkit-gradient(linear, 0% 100%, 0% 100%, from(#8EC4FF), to(#DDACFF));      /* Safari 4-5, Chrome 1-9*/
	// background-image: -webkit-linear-gradient(right, #8EC4FF, #DDACFF);   /*Safari5.1 Chrome 10+*/
	// background-image: -o-linear-gradient(right, #8EC4FF, #DDACFF);  /*Opera 11.10+*/
	.leijishouyi {
		margin-bottom: 0.1rem;
	}
	.list_item {
		padding: 0.1rem 0;
		display: flex;
		align-items: center;
		.phone {
			width: 35%;
		}
		.leiji {
			width: 33%;
		}
		.jinri {
			width: 32%;
		}
	}
}
.rankCon {
	height: 2.85rem;
	border-radius: 6px;
	// display: flex;
	margin-top: -0.5rem;
	position: relative;
	.rankItem {
		position: absolute;
		left: 0;
		top: 0;
		text-align: center;
		// position: relative;
		.crown {
			position: absolute;
			top: 0.3rem;
			left: 50%;
			transform: translate(-50%, -70%);
			z-index: 10;
		}
		.headImg {
			background-color: #ECF6FD;
			margin-top: 0.3rem;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			object-fit: cover;
		}
		.name {
			font-family: PingFangSC-Medium;
			margin: 0 0.29rem;
			padding-bottom: 0.08rem;
			color: #5569FC;
			font-size: 12px;
			font-weight: 600;
			border-bottom: 0.005rem solid rgba(111, 121, 137, 0.39);
		}
		.label {
			color: #666666;
			font-size: 12px;
			transform: scale(0.83);
		}
		.value {
			font-family: PingFangSC-Medium;
			margin-top: 0.16rem;
			font-size: 0.24rem;
			color: #303133;
			font-weight: 600;
			// margin-bottom: 8px;
		}
		.value1 {
			font-family: PingFangSC-Medium;
			margin-top: 0.08rem;
			font-size: 0.24rem;
			color: #303133;
			font-weight: 600;
			// margin-bottom: 8px;
		}
	}
	.rankItem1 {
		position: absolute;
		right: 0;
		top: 0;
		text-align: center;
		// position: relative;
		.crown {
			position: absolute;
			top: 0.3rem;
			left: 50%;
			transform: translate(-50%, -70%);
			z-index: 10;
		}
		.headImg {
			margin-top: 0.3rem;
			background-color: #ECF6FD;
			width: 31px;
			height: 31px;
			border-radius: 50%;
			object-fit: cover;
		}
		.name {
			font-family: PingFangSC-Medium;
			margin: 0 0.29rem;
			padding-bottom: 0.08rem;
			color: #5569FC;
			font-weight: 600;
			font-size: 12px;
			border-bottom: 0.005rem solid rgba(111, 121, 137, 0.39);
		}
		.label {
			color: #666666;
			font-size: 12px;
			transform: scale(0.83);
		}
		.value {
			font-family: PingFangSC-Medium;
			margin-top: 0.16rem;
			font-size: 0.24rem;
			color: #303133;
			font-weight: 600;
		}
		.value1 {
			font-family: PingFangSC-Medium;
			margin-top: 0.08rem;
			font-size: 0.24rem;
			color: #303133;
			font-weight: 600;
		}
	}
	.rankTop1Con {
		width: 34%;
		margin: 0 auto;
		position: relative;
		top: -0.3rem;
		background-image: url(../../assets/imgs/home/one_ranklist_bg.png);
		background-size: 100% 3.39rem;
		height: 3.39rem;
		.shadow {
			width: 8px;
			top: 0.6rem;
			left: -8px;
			position: absolute;
		}
		.shadow2 {
			width: 8px;
			top: 0.6rem;
			right: -8px;
			position: absolute;
		}
		.guanjun {
			position: absolute;
			top: 0;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			width: 100%;
			bottom: 0;
			.crown {
				position: absolute;
				top: 0.16rem;
				left: 50%;
				transform: translate(-50%, -70%);
				z-index: 10;
			}
			.headImg {
				background-color: #ECF6FD;
				margin-top: 0.1rem;
				margin-bottom: 0.2rem;
				width: 36px;
				height: 36px;
				border-radius: 50%;
				object-fit: cover;
			}
		}
		.name {
			margin: 0 0.29rem;
			padding-bottom: 0.08rem;
			color: #5569FC;
			font-size: 12px;
			margin-top: 0.2rem;
			font-weight: 600;
			border-bottom: 0.005rem solid rgba(111, 121, 137, 0.39);
		}
	}
}
.tab_bottom {
	display: flex;
	justify-content: space-between;
	position: fixed;
	left: 0;
	bottom: 0;
	height: 1.14rem;
	width: 100%;
	background-color: #ffffff;
	border-top: 0.005rem solid #dedede;
	.tab_item {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0.14rem 0.8rem;
		.logo {
			width: 0.4rem;
			height: 0.4rem;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.logo1 {
			width: 0.32rem;
			height: 0.4rem;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.txt {
			color: #acb5bc;
			margin-top: 0.04rem;
			font-size: 0.24rem;
			transform: scale(0.916);
		}
		.active {
			color: #efba0d;
			font-family: PingFangSC-Medium;
		}
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;