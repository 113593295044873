.head_left {
  display: flex;
  align-items: center;
}
.head_left .head_left_1 {
  margin-top: 0.2rem;
}
.head_left .head_left_1 img {
  width: 0.46rem;
  height: 0.46rem;
}
.head_left .head_left_2 {
  margin: 0.1rem;
}
.head_left .head_left_2 img {
  width: 1.24rem;
  height: 0.15rem;
}
.head_right {
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
}
.head_right .xiaoxi img {
  width: 0.4rem;
  height: 0.4rem;
}
.head_right .yuyanqiehuan {
  margin-left: 0.59rem;
}
.head_right .yuyanqiehuan img {
  width: 0.4rem;
  height: 0.4rem;
}
.head_bgcolor {
  background-color: #ffffff;
  padding: 0 0.2rem;
  margin-bottom: 0.2rem;
  padding-top: 0.1rem;
}
.layoutBox {
  padding: 0 0.3rem;
}
.menuBox {
  background-color: #ffffff;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
  padding: 0.28rem 0.2rem 0.28rem 0.2rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.2rem;
}
.swipeBanner {
  height: 2.72rem;
  margin-bottom: 0.02rem;
  border-radius: 0.16rem;
  overflow: hidden;
}
.swipeBanner .banner {
  width: 7.3rem;
  height: 2.72rem;
  display: block;
  border-radius: 0.16rem;
  overflow: hidden;
}
.swipeBanner .custom-indicator {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.1rem;
  display: flex;
}
.swipeBanner .custom-indicator .custom-indicator-item {
  margin: 0 0.06rem;
}
.swipeNotice {
  height: 0.56rem;
  line-height: 0.6rem;
  padding: 0 0.15rem 0 0.65rem;
  background: #fff url(../../assets/imgs/new_color/xiaoxi.png) no-repeat left 0.15rem center;
  background-size: 0.28rem 0.28rem;
  border-radius: 0.1rem;
  overflow: hidden;
  color: #14181f;
  font-size: 0.24rem;
  transform: scale(0.916);
  margin-left: -0.4rem;
}
.swipeNotice .xiaoxi_logo {
  padding-top: 0.18rem;
  margin-right: 0.15rem;
  width: 0.3rem;
  height: 0.3rem;
}
.swipeNotice .icon_arrR {
  float: right;
  margin: 0.2rem 0.1rem 0 0.2rem;
}
.swipeNotice .van-swipe-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.rankBox {
  height: 4.2rem;
  margin: 0.2rem 0;
  padding: 0 0.2rem;
  background-image: url(../../assets/imgs/new_color/paihang_bg.png);
  background-size: 100% 4.2rem;
  background-repeat: no-repeat;
  position: relative;
}
.rankBox .caption {
  padding-top: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.6rem;
  margin: 0 0.21rem 0 0.05rem;
  margin-bottom: 0.8rem;
}
.rankBox .caption .title {
  font-family: PingFangSC-Medium;
  font-size: 0.32rem;
  color: #333333;
  font-weight: 600;
}
.rankBox .caption .more {
  font-size: 0.24rem;
}
.rankBox .caption .icon_arrR {
  width: 0.18rem;
  height: 0.18rem;
}
.rankBox .list {
  display: flex;
  align-items: flex-end;
}
.indexTabList {
  margin-top: 10px;
  padding: 0 0.31rem 0 0.26rem;
}
.indexTabList .tabTit {
  color: #999999;
  font-size: 0.32rem;
  font-family: 'Bahnschrift', 'PingFangSC-Regular', 'PingFang SC', '微软雅黑', 'Microsoft Yahei';
  line-height: 0.7rem;
  height: 0.7rem;
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.1rem;
  text-transform: capitalize;
}
.indexTabList .tabTit li {
  padding-bottom: 0.68rem;
}
.indexTabList .tabTit .on {
  color: #373737;
  font-size: 0.4rem;
  font-weight: bold;
  border-bottom: 1px solid #373737;
}
.menuLine {
  padding: 8px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menuLine .menuName {
  font-size: 0.24rem;
  color: #373737;
  display: inline-block;
  margin-top: 0.03rem;
}
.menuLine .menuItem {
  font-size: 0.28rem;
  color: #8f9eab;
  line-height: 0.6rem;
  padding: 0 0.3rem;
  min-width: 20%;
  position: relative;
  text-transform: capitalize;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.menuLine .menuItem:first-child {
  padding-left: 0;
}
.menuLine .menuItem:first-child::before {
  display: none;
}
.menuLine .menuItem:last-child {
  padding-right: 0;
}
.menuLine .menuItem:last-child {
  margin-right: 0;
}
.menuLine .menuItem.on {
  font-family: PingFangSC-Medium;
  font-weight: 600;
  font-size: 0.28rem;
  color: #333333;
}
.menuLine .dropdownBtn {
  color: #333333;
  font-size: 0.28rem;
  font-weight: bold;
}
.menuLine1 {
  padding: 8px 0;
  position: relative;
  display: flex;
  align-items: center;
}
.menuLine1 .menuName {
  font-size: 0.24rem;
  color: #373737;
  display: inline-block;
  margin-top: 0.03rem;
}
.menuLine1 .menuItem {
  font-size: 0.28rem;
  color: #8f9eab;
  line-height: 0.6rem;
  padding: 0 0.3rem;
  min-width: 20%;
  position: relative;
  text-transform: capitalize;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.menuLine1 .menuItem:first-child {
  padding-left: 0;
  padding-right: 0.6rem;
}
.menuLine1 .menuItem:first-child::before {
  display: none;
}
.menuLine1 .menuItem:last-child {
  padding-right: 0;
}
.menuLine1 .menuItem:last-child {
  margin-right: 0;
}
.menuLine1 .menuItem.on {
  font-family: PingFangSC-Medium;
  font-weight: 600;
  font-size: 0.28rem;
  color: #333333;
}
.menuLine1 .dropdownBtn {
  color: #333333;
  font-size: 0.28rem;
  font-weight: bold;
}
.indexLoading {
  text-align: center;
  padding-top: 1rem;
}
.bottomTips {
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: calc(env(safe-area-inset-bottom) + 1.18rem);
  height: 0.28rem;
  width: 100%;
  background-color: #f2f2f2;
  padding: 0 0.1rem;
}
.bottomTips .z_left {
  font-size: 0.24rem;
  color: #333333;
  transform: scale(0.83);
}
.bottomTips .z_right {
  font-size: 0.24rem;
  color: #333333;
  transform: scale(0.83);
}
.list-item0 {
  margin-left: 0.2rem;
}
.list-item2 {
  margin-left: 0.2rem;
}
.container {
  font-family: PingFangSC-Regular;
  background-color: #f5f5f5;
}
.new_list {
  font-size: 12px;
  color: #ffffff;
  padding: 0.2rem 0.4rem 0.2rem 0.3rem;
  border-radius: 6px;
  background-image: linear-gradient(to right, #8ec4ff, #ddacff);
  background-color: #8ec4ff;
}
.new_list .leijishouyi {
  margin-bottom: 0.1rem;
}
.new_list .list_item {
  padding: 0.1rem 0;
  display: flex;
  align-items: center;
}
.new_list .list_item .phone {
  width: 35%;
}
.new_list .list_item .leiji {
  width: 33%;
}
.new_list .list_item .jinri {
  width: 32%;
}
.rankCon {
  height: 2.85rem;
  border-radius: 6px;
  margin-top: -0.5rem;
  position: relative;
}
.rankCon .rankItem {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
}
.rankCon .rankItem .crown {
  position: absolute;
  top: 0.3rem;
  left: 50%;
  transform: translate(-50%, -70%);
  z-index: 10;
}
.rankCon .rankItem .headImg {
  background-color: #ECF6FD;
  margin-top: 0.3rem;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}
.rankCon .rankItem .name {
  font-family: PingFangSC-Medium;
  margin: 0 0.29rem;
  padding-bottom: 0.08rem;
  color: #5569FC;
  font-size: 12px;
  font-weight: 600;
  border-bottom: 0.005rem solid rgba(111, 121, 137, 0.39);
}
.rankCon .rankItem .label {
  color: #666666;
  font-size: 12px;
  transform: scale(0.83);
}
.rankCon .rankItem .value {
  font-family: PingFangSC-Medium;
  margin-top: 0.16rem;
  font-size: 0.24rem;
  color: #303133;
  font-weight: 600;
}
.rankCon .rankItem .value1 {
  font-family: PingFangSC-Medium;
  margin-top: 0.08rem;
  font-size: 0.24rem;
  color: #303133;
  font-weight: 600;
}
.rankCon .rankItem1 {
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
}
.rankCon .rankItem1 .crown {
  position: absolute;
  top: 0.3rem;
  left: 50%;
  transform: translate(-50%, -70%);
  z-index: 10;
}
.rankCon .rankItem1 .headImg {
  margin-top: 0.3rem;
  background-color: #ECF6FD;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  object-fit: cover;
}
.rankCon .rankItem1 .name {
  font-family: PingFangSC-Medium;
  margin: 0 0.29rem;
  padding-bottom: 0.08rem;
  color: #5569FC;
  font-weight: 600;
  font-size: 12px;
  border-bottom: 0.005rem solid rgba(111, 121, 137, 0.39);
}
.rankCon .rankItem1 .label {
  color: #666666;
  font-size: 12px;
  transform: scale(0.83);
}
.rankCon .rankItem1 .value {
  font-family: PingFangSC-Medium;
  margin-top: 0.16rem;
  font-size: 0.24rem;
  color: #303133;
  font-weight: 600;
}
.rankCon .rankItem1 .value1 {
  font-family: PingFangSC-Medium;
  margin-top: 0.08rem;
  font-size: 0.24rem;
  color: #303133;
  font-weight: 600;
}
.rankCon .rankTop1Con {
  width: 34%;
  margin: 0 auto;
  position: relative;
  top: -0.3rem;
  background-image: url(../../assets/imgs/home/one_ranklist_bg.png);
  background-size: 100% 3.39rem;
  height: 3.39rem;
}
.rankCon .rankTop1Con .shadow {
  width: 8px;
  top: 0.6rem;
  left: -8px;
  position: absolute;
}
.rankCon .rankTop1Con .shadow2 {
  width: 8px;
  top: 0.6rem;
  right: -8px;
  position: absolute;
}
.rankCon .rankTop1Con .guanjun {
  position: absolute;
  top: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  bottom: 0;
}
.rankCon .rankTop1Con .guanjun .crown {
  position: absolute;
  top: 0.16rem;
  left: 50%;
  transform: translate(-50%, -70%);
  z-index: 10;
}
.rankCon .rankTop1Con .guanjun .headImg {
  background-color: #ECF6FD;
  margin-top: 0.1rem;
  margin-bottom: 0.2rem;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}
.rankCon .rankTop1Con .name {
  margin: 0 0.29rem;
  padding-bottom: 0.08rem;
  color: #5569FC;
  font-size: 12px;
  margin-top: 0.2rem;
  font-weight: 600;
  border-bottom: 0.005rem solid rgba(111, 121, 137, 0.39);
}
.tab_bottom {
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 1.14rem;
  width: 100%;
  background-color: #ffffff;
  border-top: 0.005rem solid #dedede;
}
.tab_bottom .tab_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.14rem 0.8rem;
}
.tab_bottom .tab_item .logo {
  width: 0.4rem;
  height: 0.4rem;
}
.tab_bottom .tab_item .logo img {
  width: 100%;
  height: 100%;
}
.tab_bottom .tab_item .logo1 {
  width: 0.32rem;
  height: 0.4rem;
}
.tab_bottom .tab_item .logo1 img {
  width: 100%;
  height: 100%;
}
.tab_bottom .tab_item .txt {
  color: #acb5bc;
  margin-top: 0.04rem;
  font-size: 0.24rem;
  transform: scale(0.916);
}
.tab_bottom .tab_item .active {
  color: #efba0d;
  font-family: PingFangSC-Medium;
}
